import React from 'react';
import Navigation from './Navigation';
import { ReactComponent as IconCheckOrange } from 'assets/icons/IconCheckOrange.svg';

// import dataItems from "../data/dataItems";
class Explanation extends React.Component {
  getParagraphs = () => {
    const text = this.props.dataItems[this.props.name].content;
    let paragraphs = [text];

    if (text.includes('<br/>')) {
      paragraphs = text.split('<br/>');
    }

    return paragraphs;
  };

  render() {
    const paragraphs = this.getParagraphs();

    const items = this.props.dataItems[this.props.name].list.map((item) => (
      <div className="align-center mb-30 mh-10" key={item}>
        <IconCheckOrange />
        <br />
        {item}
      </div>
    ));

    return (
      <div className="fadeIn">
        <div style={{ maxWidth: '720px', margin: '0 auto', fontSize: '14px' }}>
          {paragraphs &&
            paragraphs.map((text) => {
              return (
                <p className="mb-30 align-center" key={text}>
                  {text}
                </p>
              );
            })}

          <div className="explain-items">{items}</div>
        </div>

        <Navigation
          data={true}
          step={this.props.step}
          nextStep={this.props.nextStep}
          lastStep={this.props.lastStep}
          loadingParcours={this.props.loadingParcours}
        />
      </div>
    );
  }
}

export default Explanation;
