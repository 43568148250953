/* eslint-disable react/prop-types */
import React from 'react';
import Amount from 'components/Amount';
import InfoHoverBlock from 'components/success/InfoHoverBlock';
import dataMPRcolors from 'data/dataMPRcolors';

class PrimeBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //   showPicker: false
    };
  }

  render() {
    const MaPrimeRenov = this.props.APIresult['Montant MPR unitaire (€)'];
    const PrimeHellio = this.props.APIresult['Montant prime CEE unitaire (€)'];
    return (
      <div>
        {!!PrimeHellio && PrimeHellio !== '0' && (
          <div
            className={
              MaPrimeRenov || this.props.resultCodePromo.data.montant_promo
                ? 'success-item-right no-border'
                : 'success-item-right'
            }
          >
            <div>
              <InfoHoverBlock
                data={this.props.APIresult}
                success={this.props.success}
                item="Prime1"
                showTooltip
              ></InfoHoverBlock>
            </div>
            <div>
              <h5 className="h5-prime whitespace-nowrap">
                - <Amount value={PrimeHellio} />
              </h5>
            </div>
          </div>
        )}

        {MaPrimeRenov !== null && (
          <div className="success-item-right">
            <div>
              <InfoHoverBlock
                data={this.props.APIresult}
                success={this.props.success}
                item="Prime2"
                showTooltip
              ></InfoHoverBlock>
            </div>
            <div>
              <h5
                className="h5-prime whitespace-nowrap"
                style={{
                  backgroundColor:
                    dataMPRcolors[this.props.APIresult['Couleur MPR']].bg,
                }}
              >
                - <Amount value={MaPrimeRenov} />
              </h5>
            </div>
          </div>
        )}

        {this.props.resultCodePromo.data.montant_promo && (
          <div className="success-item-right">
            <div className="info-block-container">
              <p className="is-bold fz22">Code Promo</p>
            </div>
            <div>
              <h5 className="h5-prime whitespace-nowrap">
                -{' '}
                <Amount value={this.props.resultCodePromo.data.montant_promo} />
              </h5>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PrimeBlock;
