const dataRoutes = {
  Q1: 'projet-travaux',
  Q2: 'logement-type',
  'Q2.V2': 'logement-type',
  Q3: 'logement-construction',
  Q4: 'isolation',
  Q5: 'isolation-combles',
  Q6: 'isolation-murs',
  Q7: 'changement-chauffage',
  Q8: 'logement-surface',
  Q9: 'vehicule-electrique',
  Q10: 'voiture-modele',
  Q11: 'puissance-installation',
  Q12: 'cable-distance',
  // 'Q13': '/Q13',
  Q14: 'audit',
  Q15: 'mensualite',
  Q16: 'contrat-electrique',
  Q17: 'logement',
  Q18: 'syndic-coordonnees',
  Q19: 'logement-menage',
  Q20: 'proprietaire-locataire',
  'Q20.V2': 'proprietaire-locataire',
  // 'Q21': '',
  Q22: 'logement-energie',
  Q23: 'energie-fioul',
  Q24: 'energie-electricite',
  Q25: 'energie-gaz',
  Q26: 'adresse-projet',
  Q27: 'adresse-fiscale',
  Q28: 'temps-travaux',
  Q29: 'coordonnees',
  Q30: 'accompagnement',
  Q31: 'code-parrain',
  Q32: 'newsletter',
  Q33: 'Q33',
  Q34: 'energie-charbon',
  Q36: 'information-renovation-globale',
  Q37: 'information-audit-energetique',
  Q38: 'simulation-projet-final',
  Q39: 'renovation-globale-isolation',
  Q40: 'preference-chauffage',
  Q41: 'type-combles',
  Q42: 'type-murs',
  Q43: 'devis',
  Q44: 'surface-isolation',
  Q45: 'primes',
  Q46: 'nombre-fenetre',
  Q47: 'surface-chauffe',
  Q48: 'type-audit',
  Q49: 'type-fenetre',
  Q50: 'type-PAC',
  Q51: 'usage-pac',
  Q52: 'type-chaudiere',
  'Q53.V2': 'type-chauffage',
  Q54: 'robinets-thermostatiques',
  Q55: 'thermostat',
  Q56: 'nombre-émetteurs',
  Q53: 'etiquette-energetique',
  'Q4.V2': 'accompagnateur-renov',
  Success: 'simulation-projet',
};

export default dataRoutes;
